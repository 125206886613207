// Mobile Menu
const navLinks = document.getElementById('navLinks')
const faClose = document.querySelector('.fa-close')
const faBars = document.querySelector('.fa-bars')

const showMenu = () => {
    navLinks.style.right = "0";
}

const hideMenu = () => {
    navLinks.style.right = "-200px";
}

faClose.addEventListener('click',hideMenu)
faBars.addEventListener('click',showMenu)

// Hero Angles Parallax
const leftAngles = document.querySelector('.hero-left-angles')
const rightAngles = document.querySelector('.hero-right-angles')
const heroLogoMain = document.querySelector('.hero-logo-main')
const heroLogoSub = document.querySelector('.hero-logo-sub')

window.addEventListener('scroll', () => {      
    heroLogoMain.style.transform = `translateX(${window.scrollY * 1}px)`;
    heroLogoSub.style.transform = `translateX(${window.scrollY * 1}px)`;
    leftAngles.style.transform = `translateY(${window.scrollY / 4}px)`;
    rightAngles.style.transform = `translateY(${window.scrollY / 4}px)`;
})

// News Button
const newsBtn = document.querySelector('.btn-news')
const openBrowserWindow = (url) => {
    window.open(url)
}
newsBtn.addEventListener('click',openBrowserWindow('https://chimetv.co/news.html'))